import React, { useState } from 'react'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import PoliticaDePrivacidade from '../popups/PoliticaDePrivacidade'

const TermosForm = ({ register, errors }) => {
  const [buttonPopup, setButtonPopup] = useState(false)

  //validação de preenchimento
  const obrigatorio = { required: true }

  return (
    <div className="termosAceite">
      <FormGroup className="termosGroup">
        <div className="politicaCheck" id="politicaCheck">
          <FormControlLabel
            control={
              <Checkbox
                {...register('AutorizaLgpd', obrigatorio)}
                className="checkbox"
              />
            }
          />
          <p className="label">
          Autorizo que os meus dados pessoais (nome, e-mail, telefone e cidade onde resido) sejam utilizados exclusivamente para 
          retorno e envio de comunicações referentes ao contato solicitado. Após o término do tratamento dos dados, 
          os mesmos serão descartados, em conformidade com Lei 13.709/18, Lei Geral de Proteção de Dados. Ao informar meus dados, 
          eu concordo com a{' '}
            <a href="#politicaCheck" onClick={() => setButtonPopup(true)}>
              Política de Privacidade
            </a>
            .
          </p>
        </div>
        {errors.AutorizaLgpd && (
          <span className="erroForm">&gt; Autorize antes de enviar.</span>
        )}

        <FormControlLabel
          control={
            <Checkbox
              {...register('AutorizaSicredi', obrigatorio)}
              className="checkbox"
            />
          }
          label="Autorizo Sicoob ter acesso às informações sobre subsídios e responsabilidades por garantias constantes em meu nome, perante Instituições de crédito e registradas no Sistema de Informações de crédito do Banco Central (SCR), no SISBACEN ou nos sistemas que venham a complementá-lo e/ou a substituí-lo, conforme normas em vigor, bem como registros em órgão de proteção ao de crédito. Autorizo ainda a concessão de certidão de subsídios municipais, estaduais e federais, para fins de análise de crédito."
        />
        {errors.AutorizaSicredi && (
          <span className="erroForm">&gt; Autorize antes de enviar.</span>
        )}

        <FormControlLabel
          control={
            <Checkbox
              {...register('AutorizaConsultoriaSebrae')}
              className="checkbox"
            />
          }
          label="Tenho interesse em consultoria do Sebrae. Autorizo que meus dados sejam realizados ao Sebrae para que eu entre em contato, a fim de, formalizar a consultoria."
        />

        <FormControlLabel
          control={
            <Checkbox
              {...register('AutorizaCompartilhaDados', obrigatorio)}
              className="checkbox"
            />
          }
          label="Autorizo o compartilhamento de dados e informações a meu respeito com a RS Garanti, OSCIP que opera o programa de concessão de crédito municipal."
        />
        {errors.AutorizaCompartilhaDados && (
          <span className="erroForm">&gt; Autorize antes de enviar.</span>
        )}
      </FormGroup>

      <p className="docSolicita">
        A documentação completa será solicitada somente após análise das
        informações fornecidas no formulário.
      </p>
      <p className="docAnalise">
      Após pré-análise o Sicoob ou 
      a Garantidora RS Garanti farão contato com sua empresa, preferencialmente por e-mail.
      </p>

      <PoliticaDePrivacidade trigger={buttonPopup} setTrigger={setButtonPopup}>
        <div className="popupctd">
          <h3>Política de Privacidade</h3>
          <p>1. Da Adequação à Lei Geral de Proteção de Dados</p>
          <p>
            1.1. Caso a execução deste Contrato requeira que a Contratada efetue
            qualquer tipo de tratamento de dados pessoais, isto é, que faça uso
            de informações relacionadas a pessoa natural identificada ou
            identificável, a Contratada se obriga a cumprir as normas de
            proteção de dados aplicáveis à espécie, notadamente a Lei Federal
            13.709 de 14 de agosto de 2018 (a Lei Geral de Proteção de Dados -
            “LGPD”).
          </p>
          <p>1.2. A Contratada expressamente declara que:</p>
          <p>
            (i) implantou programa de governança em privacidade, e que está
            plenamente aderente à LGPD;{' '}
          </p>
          <p>
            (ii) efetuou o mapeamento de todas as suas operações de tratamento
            de dados, e que nenhum dado pessoal é tratado à míngua do devido
            enquadramento em pelo menos uma das hipóteses legais previstas no
            artigo 7º, da LGPD, e do respeito aos princípios norteadores do
            artigo 6º, da LGPD;
          </p>
          <p>
            (iii) possui estrutura operante para recepcionar e atender, de forma
            adequada, petições e/ou comunicações dos titulares de dados
            pessoais, nas quais seja exigido o atendimento a qualquer dos
            direitos previstos na LGPD;
          </p>
          <p>
            (iv) adota todas as medidas técnicas e organizacionais adequadas
            para garantir a segurança dos dados pessoais tratados, de acordo com
            as melhores práticas de tecnologia e segurança da informação;
          </p>
          <p>
            (v) se enquadra na condição de operadora de dados pessoais,
            vinculada ao CONTRATANTE, realiza todo e qualquer tratamento de
            dados pessoais exclusivamente conforme as orientações que lhe são
            fornecidas, para a finalidade de cumprir as obrigações contratuais
            ora pactuadas;
          </p>
          <p>
            (vi) nomeou um Encarregado (DPO), o qual está apto a atuar como
            canal de comunicação os titulares dos dados e a Autoridade Nacional
            de Proteção de Dados (a “ANPD”);
          </p>
          <p>
            (vii) possui Plano de Prevenção e Resposta a Incidentes com
            vazamento de dados, bem como Comitê de Gestão de Crises, ambos
            ativos e operantes e liderados pelo seu Encarregado (o “DPO”).
          </p>
          <p>
            1.3. Em caso de exposição/vazamento de dados ou outra violação à
            LGPD, decorrente do tratamento de dados pessoais do CONTRATANTE pela
            CONTRATADA, esta obriga-se a comunicar o fato imediatamente ao
            CONTRATANTE, para que este tome as providencias cabíveis e
            necessárias no prazo máximo de 24 (vinte e quatro) horas, a contar
            da ciência do incidente por qualquer uma das Partes.
          </p>
          <p>
            1.4. A Contratada obriga-se a guardar registro de todas as operações
            de tratamento de dados efetuados em razão do cumprimento deste
            Contrato, e a compartilhá-los com o CONTRATANTE, de forma
            estruturada, mediante solicitação escrita.
          </p>
          <p>
            1.5. Uma vez terminado este Contrato, a Contratada obriga-se,
            expressamente, a excluir todo e qualquer dado pessoal tratado para a
            finalidade de execução deste Contrato, inclusive backups e arquivos
            externos, isentando o CONTRATANTE de responsabilidade por qualquer
            dano e prejuízo, direto ou indireto, advindos de tratamento de dados
            pessoais perpetrados após o término deste Contrato.
          </p>
          <p>
            1.5. Uma vez terminado este Contrato, a Contratada obriga-se,
            expressamente, a excluir todo e qualquer dado pessoal tratado para a
            finalidade de execução deste Contrato, inclusive backups e arquivos
            externos, isentando o CONTRATANTE de responsabilidade por qualquer
            dano e prejuízo, direto ou indireto, advindos de tratamento de dados
            pessoais perpetrados após o término deste Contrato.
          </p>
          <p>
            1.7. Sem prejuízo do disposto acima, caso o CONTRATANTE autorize a
            subcontratação de determinados serviços a favor de terceiros, que
            impliquem no fornecimento de dados pessoais referidos nesta
            cláusula, a Contratada se compromete a celebrar, antes da
            subcontratação, um acordo de confidencialidade dos dados com a
            subcontratada, bem como a estender contratualmente à subcontratada
            todas as suas obrigações previstas no que se refere ao tratamento de
            dados pessoais, previstas neste Contrato.
          </p>
          <p>
            1.8. A Contratada se compromete a isentar o CONTRATANTE de qualquer
            demanda administrativa, judicial ou extrajudicial relacionada ao
            descumprimento das obrigações da Contratada no que se refere ao
            tratamento de dados pessoais, previstas neste Contrato, cabendo à
            Contratada arcar, com exclusividade, com o pagamento das quantias
            que, eventualmente, o CONTRATANTE seja obrigado a desembolsar em
            decorrência de condenações judiciais, sanções administrativas,
            multas, compensações, juros, danos e prejuízos em geral.
          </p>
          <p>
            1.9. O CONTRATANTE e titular dos dados poderá solicitar, via
            telefone (3003 4770 (capitais e regiões metropolitanas) ou 0800 724
            4770 demais regiões, selecionando a opção 8), à CONTRATADA, a
            qualquer tempo, o acesso aos dados que lhe digam respeito, bem como
            a sua ratificação, eliminação ou limitação de uso, a portabilidade
            de seus dados, ou ainda opor-se ao tratamento, salvo nos casos
            previstos em lei, tudo nos termos do art. 18 da Lei 13.709/2018.
          </p>
        </div>
      </PoliticaDePrivacidade>
    </div>
  )
}

export default TermosForm
