let agencias = [
    {
        id: 3050,
        name: 'SICOOB TRES FRONTEIRAS - CENTRO'
    },{
        id: 3348,
        name: 'SICOOB TRES FRONTEIRAS - SINDILOJAS'
    },{
        id: 4414,
        name: 'SICOOB TRES FRONTEIRAS - MORADA DO VALE'
    }
]

export {agencias}

            
