import React from 'react'
import card4 from '../assets/imgs/ico-4.png'
import card5 from '../assets/imgs/ico-5.png'
import card6 from '../assets/imgs/ico-6.png'
import card7 from '../assets/imgs/ico-7.png'
import '../assets/css/BcxSectionC.css'


const BcxSectionC = () => {
    return (
        <section id='sectionC'>
            <div className="container">
                <h2 className='title'>
                    Condições e <br /><strong>taxas diferenciadas</strong>
                </h2>
                <div className="cards">
                    <div className="card">
                        <img src={card4} alt="Icone Item 1" />
                        <h3><strong>Taxa de Juros:</strong>
                        <br />
                        Taxa de juros Pré-fixada de 1,59% a.m.
                        <br />
                        </h3>
                    </div>
                    <div className="card">
                        <img src={card5} alt="Icone Item 2" />
                        <h3><strong>Prazo de pagamento:</strong><br />até 36 meses<br />(até 6 meses de carência)</h3>
                    </div>
                    <div className="card">
                        <img src={card6} alt="Icone Item 3" />
                        <h3>Valores de <strong>até R$ 50 mil,</strong><br />conforme capacidade de pagamento.</h3>
                    </div>
                    <div className="card">
                        <img src={card7} alt="Icone Item 3" />
                        <h3><strong>Garantia:</strong><br />aval dos sócios e da RS Garanti</h3>
                    </div>
                </div>
                <div className="rodape">
                    {/* <p>*Condições mediante disponibilidade e aprovação do crédito (capacidade de pagamento e sem restrições).</p>
                    <p>**Condições especiais Sicredi Pioneira neste momento de calamidade.</p> */}
                </div>
            </div>
        </section>
    )
}

export default BcxSectionC